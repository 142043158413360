<template>
  <div class="w-p-100 h-p-100 Fbg">
    <div class="scroll-y w-p-100 Fbg">
      <van-cell
        title="入场培训"
        class="h-35 flex alignCenter"
        style="width:50%; background:linear-gradient(-135deg,#fff 45px, #A3BED5 0 )"
      />
      <div class="tP-20 bP-20 lP-10 rP-10 Fbg">
        <van-row>
          <van-col span="12" v-for="item in displayTrain.admission" :key="item">
            <van-button
              type="default"
              class="size-14 w-calc-24 radius-10 border-808080"
              style="padding:0"
              @click="goToTrainPage(item.temptrain_type)"
              >{{ item.train_type }}</van-button
            >
          </van-col>
        </van-row>
      </div>
    </div>
    <div class="scroll-y w-p-100">
      <van-cell
        title="作业票系统&特种作业授权培训"
        class="h-35  flex alignCenter"
        style="background:linear-gradient(-135deg,#fff 45px, #A3BED5 0 )"
      />
      <div class="tP-20 bP-20 lP-10 rP-10 Fbg">
        <van-row>
          <van-col
            class="tP-5 bP-5"
            span="12"
            v-for="item in displayTrain.other"
            :key="item"
          >
            <van-button
              type="default"
              class="size-14 w-calc-24 radius-10 border-808080"
              style="padding:0"
              @click="goToTrainPage(item.temptrain_type)"
              >{{ item.train_type }}</van-button
            >
          </van-col>
        </van-row>
      </div>
    </div>
    <van-popup
      v-model:show="showTrainPage"
      position="bottom"
      style="height:calc(100% - 40px)"
      :closeable="false"
      :safe-area-inset-bottom="true"
      :close-on-click-overlay="false"
      :overlay="false"
    >
      <div class="h-p-100">
        <van-list
          v-model="loading"
          @load="onLoad"
          :finished="finished"
          finished-text="没有更多了"
          class="h-calc-45 scroll-y"
        >
          <div v-if="list.length > 0" class="w-p-100 h-p-100">
            <div
              class="w-calc-48 Fbg lP-24 rP-24 bM-4 tP-5 bP-5 relative"
              v-for="item in list"
              :key="item"
            >
              <div
                class="h-45 flex alignCenter justifyBetween"
                @click="goToPreviewPage(item.id)"
              >
                <div class="size-20">{{ item.name }}</div>
                <div class="size-18 color-80">{{ item.create_time }}</div>
              </div>
            </div>
          </div>
          <div v-else>
            <van-empty description="暂无数据"></van-empty>
          </div>
        </van-list>
        <van-button
          block
          type="default"
          class="bg-808080 color-fff"
          @click="showTrainPage = false"
          >返回</van-button
        >
      </div>
    </van-popup>
  </div>
</template>
<script>
import { onMounted, reactive, ref } from "vue";
import http from "../../../api/http";
import { useRoute, useRouter } from "vue-router";
export default {
  setup() {
    const imgUrl = http.getImgUrl();
    const finished = ref(false);
    const loading = ref(false);
    const route = useRoute();
    const router = useRouter();
    const detailForm = reactive({
      source: "",
      trouble_date: "",
      finder: "",
      trouble_description: "",
      measure: "",
      rectifier: "",
      trouble_tracker: "",
      plan_handle_date: ""
    });
    const originRes = ref([]);
    const displayTrain = reactive({
      admission: [],
      other: []
    });
    const list = ref([]);
    const showTrainPage = ref(false);

    const getlist = () => {
      http
        .get({
          method: "query",
          queryId: "1000",
          t: "CT_TRAIN_DOCUMENT"
        })
        .then(res => {
          var resMap = res.map;
          // 这里只显示 train_Type== 1以外的， 3是入场培训  其他的是 作业票系统&特种作业授权培训
          resMap.forEach(item => {
            var type = item.temptrain_type;
            if (type == 3) {
              let oL = displayTrain.admission.filter(
                items => items.temptrain_type == item.temptrain_type
              );
              if (oL && oL.length == 0) {
                displayTrain.admission.push(item);
              }
            }
            if (type != 1 && type != 2 && type != 3) {
              let oL = displayTrain.other.filter(
                items => items.temptrain_type == item.temptrain_type
              );
              if (oL && oL.length == 0) {
                displayTrain.other.push(item);
              }
            }
          });
        });
    };

    const getDetail = type => {
      http
        .get({
          method: "query",
          queryId: "1000",
          t: "CT_TRAIN_DOCUMENT",
          train_type: type
        })
        .then(res => {
          list.value = res.map;
        });
    };

    const goToTrainPage = type => {
      showTrainPage.value = true;
      getDetail(type);
    };

    const goToPreviewPage = id => {
      router.push("/fileDetail/" + route.name + "/" + id);
    };
    const refresh = () => {
      loading.value = true;
      getlist();
    };
    onMounted(() => {
      getlist();
    });
    return {
      detailForm,
      goToTrainPage,
      list,
      showTrainPage,
      refresh,
      finished,
      loading,
      imgUrl,
      originRes,
      displayTrain,
      getDetail,
      goToPreviewPage,
      route,
      router
    };
  }
};
</script>
